import image1 from "../images/PoliLearn.jpg";
import image2 from "../images/DRD.jpg";
import image3 from "../images/Doglivery.jpg";
import image4 from "../images/AngelFace.jpg";
import image5 from "../images/Menya.jpg";

export default [
    {"image": image1, "link": process.env.PUBLIC_URL + "/images/Works-PoliLearn.pdf"}, 
    {"image": image2, "link": process.env.PUBLIC_URL + "/images/Works-DPD.pdf"},
    {"image": image3, "link": process.env.PUBLIC_URL + "/images/Works-Doglivery.pdf"}, 
    {"image": image4, "link": "https://www.angelface.com.hk/"}, 
    {"image": image5, "link": process.env.PUBLIC_URL + "/images/Works-Menya.pdf"}
    ];

